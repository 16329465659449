<template>
  <div class="shop-all-container max-w-rifyo mx-auto max-xl:px-5">
    <div class="mt-24 md:mt-32 text-4xl font-bold mb-5 md:mb-10" style="font-family: Lora">
      Wishlist
    </div>
    <div class="loading" v-if="isLoading">
      <div class="loader loader--style1" title="0">
        <svg
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 40 40"
          enable-background="new 0 0 40 40"
          xml:space="preserve"
        >
          <path
            opacity="0.2"
            fill="#000"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
          s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
          c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path
            fill="#000"
            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
          C22.32,8.481,24.301,9.057,26.013,10.047z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 20 20"
              to="360 20 20"
              dur="0.5s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    </div>
    <template v-if="wishlist.length">
      <div class="w-full grid grid-cols-12 gap-4">
        <wish-card
          is-wishlist
          rootClassName="col-span-6 md:col-span-4 lg:col-span-3"
          v-for="item in wishlist"
          :key="item.id"
          :image_src="item.image"
          :title="item.title"
          :harga="`IDR ${formatRupiah(item.price)}`"
          :item="item"
          @wish="wishAction"
        ></wish-card>
      </div>
      <div class="shop-all-container-pagination">
        <app-pagination
          :get-page="fecthProduct.bind(this)"
          rootClassName="rootClassName"
          :selected="selected"
          :total="total"
          :per_page="per_page"
          :current_page="current_page"
        ></app-pagination>
      </div>
    </template>
    <div class="no-wishlist mt-10" v-else>
      <img
        :src="`/playground_assets/no_wishlist.svg`"
        :alt="`image`"
        class="image-no-wishlist mb-8"
      />
      <div class="wish-title mb-4">You don’t have any wishlist.</div>
      <div class="wish-desc mb-8">You haven’t added anything to your wishlist</div>
      <button class="btn-wish mb-20" @click="$router.push('/shop/all')">
        Browse Products
      </button>
    </div>
    <Footer />
  </div>
</template>

<script>
  import InputFilter from '../components/input-filter';
  import WishCard from '../components/wish-card';
  import AppPagination from '../components/pagination';
  import Footer from '../components/footer';
  import Api from '../api';
  import axios from 'axios';

  export default {
    name: 'ShopAll',
    props: {},
    components: {
      InputFilter,
      WishCard,
      AppPagination,
      Footer,
    },
    data() {
      return {
        products: [],
        total: 0,
        per_page: 12,
        current_page: 1,
        selected: 1,
        orderBy: 'desc',
        orderData: ['latest', 'oldest', 'price_min', 'price_max'],
        categories: [],
        categoriesName: [],
        category_id: null,
        isLoading: false,
        wishlist: [],
      };
    },
    computed: {
      isAuthenticated() {
        return this.$store.state.isAuthenticated;
      },

      slug() {
        return this.$route.params.slug;
      },
    },
    created() {
      this.getWishlist();
    },
    methods: {
      async wishAction(item) {
        await axios.post(Api.wishlistUrl, {
          product_id: item.product_id,
        });
        await this.getWishlist();
      },
      fecthProduct(page) {
        this.selected = page;
        this.getWishlist();
      },
      async getWishlist() {
        try {
          if (this.isAuthenticated) {
            this.isLoading = true;
            const rawResponse = await axios.get(
              Api.wishlistUrl +
                `?perpage=${this.per_page}&page=${this.selected}`
            );

            if (rawResponse.data.status) {
              this.wishlist = rawResponse.data.data;
              this.current_page = rawResponse.data.current_page;
              this.per_page = rawResponse.data.per_page;
              this.total = rawResponse.data.total;
            }
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$router.push('/login');
          }
        } catch (error) {
          this.isLoading = false;
          this.msg_err = error.message;
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
          }, 3000);
        }
      },

      formatRupiah(angka) {
        let ang = angka.toString();
        let number_string = ang.replace(/[^,\d]/g, '').toString(),
          split = number_string.split(','),
          sisa = split[0].length % 3,
          rupiah = split[0].substr(0, sisa),
          separator = '',
          ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        if (ribuan) {
          separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah ? rupiah : '';
      },
      formatHarga(item) {
        let variant = [];
        for (let index = 0; index < item.product_variants.length; index++) {
          const element = item.product_variants[index];
          variant = variant.concat(element);
        }
        variant = variant.sort(
          (a, b) => parseFloat(a.product.price) - parseFloat(b.product.price)
        );
        return (
          'IDR ' +
          this.formatRupiah(variant[0] ? variant[0].product.price : item.price)
        );
      },
      showImage(item) {
        return item?.image?.thumbnail
          ? item.image.thumbnail
          : 'https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png';
      },
      fecthProduct(page) {
        this.selected = page;
        this.getWishlist();
      },
      orderByProduct(order) {
        this.orderBy = order;
        this.getProduct();
      },
      selectCategory(op) {
        if (op === 'ALL') {
          this.category_id = null;
          this.getProductAll();
        } else {
          const item = this.categories.find((res) => res.title === op);
          this.category_id = item.id;
          this.getProduct();
        }
      },
      goPage(page) {
        this.$router.push(page);
      },
      async getProductAll() {
        try {
          this.isLoading = true;
          let fullUrl =
            Api.productUrl +
            `&perpage=${this.per_page ? this.per_page : '10'}&page=${
              this.selected
            }&order=${this.orderBy.toLowerCase()}`;
          if (this.$route.params.slug != 'all') {
            fullUrl += `&category_slug=${this.$route.params.slug}`;
          }
          const response = await this.$http.get(fullUrl);
          this.isLoading = false;
          this.products = response.data.data;
          this.total = response.data.total;
          this.per_page = response.data.per_page;
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      },
      async getProduct() {
        try {
          this.isLoading = true;
          let fullUrl =
            Api.wishlistUrl +
            `?perpage=${this.per_page ? this.per_page : '10'}&page=${
              this.selected
            }`;
          if (this.$route.params.slug != 'all') {
            fullUrl += `&category_slug=${this.$route.params.slug}`;
          }
          if (this.category_id) {
            fullUrl = fullUrl + `&category_id=${this.category_id}`;
          }
          if (this.$route.query.search) {
            fullUrl = fullUrl + `&search=${this.$route.query.search}`;
          }
          const response = await this.$http.get(fullUrl);
          this.isLoading = false;
          this.products = response.data.data;
          this.total = response.data.total;
          this.per_page = response.data.per_page;
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      },
      async getCategory() {
        try {
          const response = await this.$http.get(Api.categoryUrl);
          this.categories = response.data.data;
          let subcategori = [];

          this.categories.forEach((a) => {
            a.child.forEach((b) => {
              subcategori = [...subcategori, b];
            });
          });
          this.categories = subcategori;
          this.categoriesName = subcategori.map((item) => item.title);
          this.categoriesName = ['ALL', ...this.categoriesName];
        } catch (error) {
          console.log(error);
        }
      },
    },
    watch: {
      slug() {
        this.getProduct();
      },
    },
  };
</script>

<style scoped>
  .no-wishlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  img.image-no-wishlist {
    height: 124px;
  }
  .wish-title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #1f252c;
    margin-bottom: 16px;
  }
  .wish-desc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #1f252c;
    margin-bottom: 16px;
  }
  button.btn-wish {
    height: 44px;
    background: #1f252c;
    color: #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    width: 273px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.24em;
    text-transform: capitalize;
  }
  .loading {
    margin-top: 164px;
    margin-bottom: 164px;
  }
  .shop-all-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .shop-all-header-shop {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-top: 160px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    justify-content: space-between;
  }
  .shop-all-shop-title {
    width: 240px;
    display: flex;
    flex-direction: column;
  }
  .shop-all-text {
    color: #1f252c;
    font-size: 2em;
    font-family: Lora;
  }
  .shop-all-text1 {
    color: #575f6e;
    font-size: 18px;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-family: Montserrat;
  }
  .shop-all-filter-row {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }
  .shop-all-shop-list {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .shop-all-container-pagination {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    justify-content: center;
  }
  .shop-card {
    width: 23%;
  }
  @media (max-width: 991px) {
    .shop-all-header-shop {
      flex-wrap: wrap;
      padding-left: 16px;
      padding-right: 16px;
    }
    .shop-all-shop-title {
      width: 100%;
    }
    .shop-all-filter-row {
      width: 100%;
      margin-top: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .shop-all-shop-list {
      padding-left: 16px;
      padding-right: 16px;
    }
    .shop-card {
      width: 100%;
    }
  }
</style>
