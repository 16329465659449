<template>
  <div class="pagination-container" :class="rootClassName">
    <div class="pagination-row-page">
      <img
        @click="prev()"
        :src="image_src"
        :alt="image_alt"
        class="pagination-image"
      />
      <div
        v-for="item in paginateNumber"
        :key="item"
        role="button"
        :class="[
          selected === item ? 'pagination-active' : 'pagination-non-active',
        ]"
        @click="goPage(item)"
      >
        <span
          :class="[
            selected === item
              ? 'pagination-text-active'
              : 'pagination-text-non-active',
          ]"
          >{{ item }}</span
        >
      </div>
      <img
        @click="next()"
        :src="image_src1"
        :alt="image_alt1"
        class="pagination-image1"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      image_src: {
        type: String,
        default: '/playground_assets/arrow-left-black.svg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      image_src1: {
        type: String,
        default: '/playground_assets/arrow-right-black.svg',
      },
      image_alt1: {
        type: String,
        default: 'image',
      },
      text: {
        type: String,
        default: '1',
      },
      text1: {
        type: String,
        default: '2',
      },
      text11: {
        type: String,
        default: '3',
      },
      rootClassName: String,
      total: Number,
      per_page: Number,
      current_page: Number,
      selected: Number,
      getPage: Function,
    },
    data() {
      return {
        paginationList: [],
      };
    },
    computed: {
      paginateNumber() {
        let numberList = [];
        let count = Math.ceil(this.total / this.per_page);
        for (let index = 0; index < count; index++) {
          numberList.push(index + 1);
        }
        return numberList;
      },
    },
    methods: {
      goPage(item) {
        this.getPage(item);
      },
      next() {
        const inc = this.selected + 1;
        if (inc <= this.paginateNumber.length) {
          this.getPage(inc);
        }
      },
      prev() {
        const minus = this.selected - 1;
        if (minus >= 1) {
          this.getPage(minus);
        }
      },
    },
  };
</script>

<style scoped>
  .pagination-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .pagination-row-page {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pagination-image {
    width: 32px;
    object-fit: cover;
    cursor: pointer;
  }
  .pagination-active {
    flex: 0 0 auto;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #1f252c;
    cursor: pointer;
  }
  .pagination-text-active {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .pagination-non-active {
    flex: 0 0 auto;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    cursor: pointer;
  }
  .pagination-text-non-active {
    color: #1f252c;
    font-size: 18px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .pagination-non-active1 {
    flex: 0 0 auto;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
  }
  .pagination-text-non-active1 {
    color: #1f252c;
    font-size: 18px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .pagination-image1 {
    width: 32px;
    object-fit: cover;
    cursor: pointer;
  }
  .rootClassName {
    width: 280px;
  }
</style>
