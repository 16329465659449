<template>
  <div class="relative" v-bind:class="rootClassName">
    <div class="w-full h-full flex flex-col justify-between relative" @click="goPage('product-detail')">
      <img :src="image_src" :alt="image_alt" class="shop-card-image mx-auto" />
      <div class="w-full">
        <span class="shop-card-text px-0">{{ title }}</span>
        <div class="shop-card-container1 px-0">
          <span class="shop-card-text1">{{ harga }}</span>
          <img :src="image_src1" :alt="image_alt1" class="shop-card-image1" />
        </div>
      </div>
    </div>
    <img v-if="isWishlist" class="z-10 absolute right-0 top-0 p-4" src="/playground_assets/love_red.svg" @click="$emit('wish', item)">
  </div>
</template>

<script>
export default {
  name: 'ShopCard',
  props: {
    isWishlist: {
      type: Boolean,
      default: false
    },
    image_src: {
      type: String,
      default: '/playground_assets/chair2x.png',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    title: {
      type: String,
      default: 'Eames Lounge Chairs & Ottoman',
    },
    harga: {
      type: String,
      default: 'IDR 1.350.000',
    },
    image_src1: {
      type: String,
      default: '/playground_assets/arrow-right-black.svg',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    rootClassName: String,
    item: Object,
  },
   methods: {
    goPage(page) {
      if(this.item){
        this.$router.push(`/${page}/${this.item.slug}`);
        this.$store.commit("setProduct", { ...this.item });
        this.$emit('redirect');
      }
    },
  },
}
</script>

<style scoped>
.shop-card-container {
  width: 100%;
  height: 350px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 10px;
  cursor:pointer;
  background: #fff;
  padding-bottom: 24px;
}
.shop-card-image {
  width: 238px;
  height: 238px;
  object-fit: contain;
}
.shop-card-text {
  color: #1F252C;
  font-size: 18px;
  font-style: normal;
  font-family: Lora;
  font-weight: 700;
  padding-left: var(--dl-space-space-unit);
  flex-grow: 1;
}
.shop-card-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  background-size: cover;
  justify-content: space-between;
}
.shop-card-text1 {
  font-size: 18px;
  font-family: Montserrat;
}
.shop-card-image1 {
  width: 24px;
  object-fit: cover;
}
.rootClassName {
  flex: 23%;
}
.rootClassName1 {
  flex: 23%;
}
.rootClassName2 {
  flex: 23%;
}
.rootClassName3 {
  flex: 23%;
}
.rootClassName4 {
  flex: 23%;
}
@media(max-width: 991px) {
  .shop-card-container {
    height: auto;
  }
  .shop-card-text {
    width: 100%;
  }
  .rootClassName {
    width: 100%;
  }
  .rootClassName1 {
    width: 100%;
  }
  .rootClassName2 {
    width: 100%;
  }
  .rootClassName3 {
    width: 100%;
  }
  .rootClassName4 {
    width: 100%;
  }
}
</style>
