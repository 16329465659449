<template>
  <div ref="dropdown" class="wrap">
    <div class="input-filter-input-box " v-bind:class="rootClassName">
      <input
        @click="onToggle()"
        type="text"
        :placeholder="selected ? formatText(selected) : textinputPlaceholder"
        readonly
        class="input-filter-textinput input cursor-pointer focus:outline-none"
        :class="{
        'bg-[#1f252c] placeholder-white': isOpen,
        '': !isOpen,
        }"
      />
      <svg
        class="input-filter-arrow-down pointer-events-none"
        :class="{
        'rotate-180 invert': isOpen,
        'rotate-0': !isOpen,
        }"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 9L12 15L18 9"
          stroke="#000"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="isOpen" class="selectbox" v-bind:class="rootClassName">
      <div
        v-for="item in optionData"
        :key="item"
        @click="onSelected(item)"
        class="optionBox"
      >
        {{ formatText(item) }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InputFilter',
    props: {
      rootClassName: String,
      textinputPlaceholder: {
        type: String,
        default: 'Filter',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      image_src: {
        type: String,
        default: '/playground_assets/chevron-down.svg',
      },
      onOption: Function,
      optionData: {
        type: Array,
        default: ['DESC', 'ASC'],
      },
    },
    data() {
      return {
        selected: null,
        isOpen: false,
      };
    },
    mounted() {
      document.addEventListener('click', this.onClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.onClickOutside);
    },
    methods: {
      onToggle() {
        this.isOpen = !this.isOpen;
      },
      onSelected(op) {
        this.isOpen = !this.isOpen;
        this.selected = op;
        this.onOption(op);
      },
      formatText(text) {
        return text.replace('_', ' ').toUpperCase();
      },
      onClickOutside(event) {
        if (this.$refs['dropdown'].contains(event.target)) return;
        this.isOpen = false;
      },
    },
  };
</script>

<style scoped>
  .wrap {
    position: relative;
  }
  .selectbox {
    position: absolute;
    top: 64px;
    z-index: 99;
    width: 100%;
    background: #fff;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #1f252c;
  }
  .optionBox {
    width: 100%;
    /* height: 54px; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1F252C;
    padding: 8px 16px;
    background: #fff;
    cursor: pointer;
    transition: .3s;
  }
  .optionBox:hover {
    background: #eae3d9;
  }
  .optionBox:first-child {
    border-top: none;
  }
  .input-filter-input-box {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .input-filter-textinput {
    width: 100%;
    height: 54px;
    font-size: 16px;
    font-family: Montserrat;
    border: 1px solid #1f252c;
    border-radius: none;
  }
  .input-filter-arrow-down {
    right: 10px;
    width: 24px;
    bottom: 14px;
    position: absolute;
    object-fit: cover;
    transition: .5s;
  }
  .rootClassName {
    width: 250px;
    margin-right: var(--dl-space-space-unit);
    margin-bottom: 1px;
  }
  .rootClassName1 {
    width: 250px;
    margin-bottom: 1px;
  }
  @media (max-width: 991px) {
    .rootClassName {
      width: 48%;
      margin-right: 0px;
    }
    .rootClassName1 {
      width: 48%;
    }
  }
</style>
